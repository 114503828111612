@font-face {
  font-family: 'Proxima Nova';
  font-weight: 100;
  src: url("../fonts/Proxima_Nova_Thin.woff") format("woff");
}

@font-face {
  font-family: 'Proxima Nova';
  font-weight: 300;
  src: url("../fonts/Proxima_Nova_Light.woff") format("woff");
}

@font-face {
  font-family: 'Proxima Nova';
  src: url("../fonts/Proxima_Nova_Regular.woff") format("woff");
}

@font-face {
  font-family: 'Proxima Nova';
  font-weight: 600;
  src: url("../fonts/Proxima_Nova_Semibold.woff") format("woff");
}

@font-face {
  font-family: 'Proxima Nova';
  font-weight: bold;
  src: url("../fonts/Proxima_Nova_Bold.woff") format("woff");
}