.description {
  @media screen and (min-width: 1280px) {
    width: 522px;
  }
  @media screen and (min-width: 1024px) and (max-width: 1279px) {
    width: 433px;
  }
  @media screen and (max-width: 1023px) {
    margin: 0 0 35px;
  }
  &_options {
    position: relative;
    top: -183px;
    @media screen and (min-width: 1024px) and (max-width: 1279px) {
      top: -160px;
    }
    @media screen and (max-width: 1023px) {
      position: static;
    }
  }
}