.preloader {
  position: fixed;
  background: $color-light-violet;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  transition: opacity 0.55s, visibility 0.55s;
  visibility: hidden;
  z-index: 10;
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &_active {
    opacity: 1;
    visibility: visible;
  }
}