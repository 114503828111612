.gallery {
  width: 780px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (min-width: 1024px) and (max-width: 1279px) {
    width: 760px;
  }
  @media screen and (max-width: 1023px) {
    width: 100%;
  }
  &__thumb {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 1023px) {
      display: none;
    }
  }
  &__thumb-slide {
    border-bottom: 4px solid $color-accent;
    background-color: $color-black;
    width: 150px;
    transition: all 0.3s;
    @media screen and (min-width: 1024px) and (max-width: 1279px) {
      width: 140px;
    }
    &:hover {
      border-color: $color-pink;
    }
    img {
      opacity: 0.5;
      &:hover {
        opacity: 1;
      }
    }
    &_active {
      border-color: $color-pink;
      img {
        opacity: 1;
      }
    }
  }
  &__controls {
    display: none;
    @media screen and (max-width: 1023px) {
      display: flex;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}