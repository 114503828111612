.media {
    background: url("../img/media-bg.jpg") 50% 50%;
    background-size: cover;
    color: $color-white;
    padding: 107px 0 149px;
    @media screen and (min-width: 1024px) and (max-width: 1279px) {
        padding: 90px 0 110px;
    }
    @media screen and (max-width: 1023px) {
        padding: 36px 0;
    }
    &__container {
        @media screen and (max-width: 1023px) {
            margin: 0;
        }
    }
    &__inner {
        display: flex;
        justify-content: space-between;
        @media screen and (max-width: 1023px) {
            flex-direction: column;
        }
    }
    &__info {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: space-between;
        flex-shrink: 0;
        background: $color-light-violet;
        width: 385px;
        height: 655px;
        padding: 30px 30px 35px;
        @media screen and (min-width: 1024px) and (max-width: 1279px) {
            width: 204px;
        }
        @media screen and (max-width: 1279px) {
            padding: 20px;
        }
        @media screen and (max-width: 1023px) {
            width: 100%;
            height: auto;
            flex-direction: row;
        }
        p {
            font-size: 1.286em;
            font-weight: 300;
            line-height: 1.4;
            margin: 0 0 38px;
            flex-shrink: 0;
            @media screen and (max-width: 1023px) {
                font-size: 1em;
                line-height: 1.7;
            }
        }
    }
    &__download {
        span {
            display: block;
            margin-bottom: 24px;
            @media screen and (max-width: 1023px) {
                font-size: 1.2em;
                margin-bottom: 15px;
            }
        }
    }
    &__button {
        display: inline-block;
        max-width: 123px;
        padding: 15px 16px 5px;
        text-decoration: none;
        color: inherit;
        background-color: rgba(123, 55, 101, 0.5);
        font-size: 0.857em;
        position: relative;
        @media screen and (max-width: 1023px) {
            font-size: 1em;
        }
        &::after {
            content: '';
            display: block;
            width: 0;
            height: 0;
            position: absolute;
            top: 0;
            right: 0;
            border-width: 10px;
            border-style: solid;
            border-color: $color-light-violet $color-light-violet #5E2146 #5E2146;
        }
    }
    &__file-name {
        line-height: 1.2;
        margin-bottom: 5px;
    }
    &__file-size {
        color: rgba($color-white, 0.5);
        margin-bottom: 7px;
    }
    &__icon {
        width: 75px;
        margin-left: -8px;
    }
}