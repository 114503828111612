.select {
  @media screen and (min-width: 1280px) {
    width: 560px;
  }
  @media screen and (min-width: 1024px) and (max-width: 1279px) {
    width: 460px;
  }
  &__container {
    margin: 15px 0 42px;
    @media screen and (max-width: 1023px) {
      margin-bottom: 21px;
    }
    .cs-box {
      color: $color-accent;
      position: relative;
    }
    .cs-box__toggle {
      display: flex;
      align-items: center;
      background: #f4f4f4;
      border: 1px solid $color-pink;
      min-height: 4.42em;
      max-width: 100%;
      padding: 0 4.4em 0 1.6em;
      position: relative;
      @media screen and (max-width: 1023px) {
        min-height: 4em;
        padding: 0 4.4em 0 1.4em;
      }
      &.is-active::before {
        transform: scaleY(-1);
      }
      &::before {
        background: url("../img/arrow-list.svg") 50% 50% no-repeat;
        background-size: contain;
        content: '';
        display: block;
        height: .58em;
        margin: -.29em 0 0;
        position: absolute;
        right: 1.7em;
        top: 50%;
        transition: transform .36s;
        width: 1em;
      }
    }
    .cs-box__dropdown {
      background: white;
      border: 1px solid $color-pink;
      margin: -1px 0 0;
      pointer-events: none;
      position: absolute;
      transition: opacity .36s;
      opacity: 0;
      transform-origin: 50% 0;
      width: 100%;
      z-index: 777;
      &.is-opened {
        pointer-events: auto;
        opacity: 1;
      }
    }
    .cs-box__option {
      padding: 1.4em 1.6em;
      transition: background-color .18s;
      @media screen and (max-width: 1023px) {
        padding: 1em 1.4em;
      }
      &.is-active {
        background: #f4f5f9;
      }
    }
    select {
      display: none;
    }
  }
  &__images {
    background-color: #fff;
    height: 390px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (min-width: 1024px) and (max-width: 1279px) {
      height: 320px;
    }
    @media screen and (max-width: 1023px) {
      height: 375px;
    }
    @media screen and (max-width: 575px) {
      height: 240px;
    }
    &_spec {
      background: #f4f4f4;
    }
    &_options {
      @media screen and (max-width: 575px) {
        height: auto;
      }
    }
  }
  &__img {
    display: none;
    max-height: 80%;
    max-width: 80%;
    &_active {
      display: block;
    }
    &_options {
      max-width: 100%;
      max-height: none;
      @media screen and (max-width: 1023px) {
        max-height: 100%;
      }
    }
  }
  &__list {
    padding-left: 20px;
    margin-bottom: 60px;
  }
}