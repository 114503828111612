.package {
    padding: 92px 0 154px;
    @media screen and (min-width: 1024px) and (max-width: 1279px) {
        padding: 70px 0 110px;
    }
    @media screen and (max-width: 1023px) {
        padding: 22px 0 50px;
    }
    &__inner {
        margin-top: 108px;
        position: relative;
        @media screen and (max-width: 1279px) {
            margin-top: 0;
        }
        @media screen and (max-width: 1023px) {}
    }
    &__header {
        width: 520px;
        height: 280px;
        background: $color-light-violet;
        color: white;
        margin: 0 0 64px;
        position: relative;
        @media screen and (min-width: 1024px) and (max-width: 1279px) {
            width: 430px;
            height: 250px;
        }
        @media screen and (max-width: 1023px) {
            margin: 0 0 80px -20px;
            width: 100vw;
            height: auto;
        }
        p {
            margin: 0;
            padding: 35px 35px 0;
            font-size: 1.14em;
            @media screen and (min-width: 1024px) and (max-width: 1279px) {
                padding: 30px;
            }
            @media screen and (max-width: 1023px) {
                padding: 30px 20px 45px;
            }
        }
    }
    &__button {
        letter-spacing: 0.21em;
        font-size: 1.143em;
        font-weight: 300;
        @media screen and (max-width: 1023px) {
            bottom: -36px;
            padding: 0 20px;
            justify-content: flex-start;
        }
        @media screen and (max-width: 425px) {
            font-size: 0.8em;
        }
        svg {
            @media screen and (max-width: 1023px) {
                margin-right: 15px;
            }
        }
    }
    &__scheme {
        @media screen and (max-width: 1023px) {
            padding: 200px 0 0;
            position: relative;
        }
    }
    &__list {
        counter-reset: package_counter;
        list-style: none;
        margin: 0;
        padding: 0 0 0 30px;
        @media screen and (max-width: 1023px) {
            padding: 0;
        }
        @media screen and (max-width: 425px) {
            margin-top: 50px;
        }
        li {
            color: $color-accent;
            font-size: 1.14em;
            counter-increment: package_counter;
            margin-bottom: 30px;
            padding-left: 39px;
            position: relative;
            &::before {
                content: counter(package_counter);
                display: flex;
                align-items: center;
                justify-content: center;
                width: 27px;
                height: 27px;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                background: #975988;
                border-radius: 100%;
                color: white;
                font-size: .8em;
            }
            &:last-child {
                margin: 0;
            }
            @media screen and (max-width: 1023px) {
                font-size: 1em;
            }
        }
        span {
            border-bottom: 1px solid rgba($color-accent, 0.3);
            padding-bottom: 6px;
        }
    }
    &__img {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate3d(0, -50%, 0);
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s linear;
        @media screen and (min-width: 1024px) and (max-width: 1279px) {
            top: 52px;
            transform: scale(0.8);
            transform-origin: 100% 0;
        }
        @media screen and (max-width: 1023px) {
            visibility: visible;
            opacity: 1;
            position: relative;
            top: 0;
            right: 0;
            transform: none;
        }
        &_active {
            opacity: 1;
            visibility: visible;
        }
        img {
            @media screen and (max-width: 1023px) {
                margin: 0 auto;
            }
        }
    }
    &__img-container {
        width: 550px;
        height: 317px;
        position: absolute;
        bottom: 50px;
        right: 30px;
        @media screen and (max-width: 1023px) {
            left: 50%;
            transform: scale(0.7) translateX(-50%);
            transform-origin: 0 0;
            height: auto;
        }
        @media screen and (max-width: 360px) {
            transform: scale(0.6) translateX(-50%);
        }
    }
    &__anchor {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 27px;
        height: 27px;
        background: #975988;
        border-radius: 100%;
        color: $color-white;
        font-size: 1.143em;
        position: absolute;
        @media screen and (max-width: 1023px) {
            transform: scale(1.5);
        }
    }
    &__choice {
        position: absolute;
        top: 0;
        right: 447px;
        @media screen and (max-width: 1279px) {
            right: 370px;
        }
        @media screen and (max-width: 1023px) {
            display: none;
        }
    }
    &__choice-item {
        width: 145px;
        height: 145px;
        border: 2px solid transparent;
        margin-bottom: 27px;
        background-color: #ededed;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        &_active {
            border-color: $color-light-violet;
            background-color: transparent;
        }
    }
    &__prev {
        position: absolute;
        left: -25px;
        top: 50%;
        transform: translate3d(0, -50%, 0) scale(1.2);
        cursor: pointer;
        z-index: 1;
    }
    &__next {
        position: absolute;
        right: -25px;
        top: 50%;
        transform: translate3d(0, -50%, 0) scale(1.2);
        cursor: pointer;
    }
}