.ext-link {
  display: flex;
  align-items: center;
  background: $color-accent;
  color: white;
  fill: white;
  padding: 6px 16px;
  position: absolute;
  text-decoration: none;
  transition: background-color .2s;
  &:hover {
    background: $color-light-violet;
  }
  @media screen and (min-width: 1024px) and (max-width: 1279px) {
    padding: 5px 10px;
  }
  svg {
    margin-right: 10px;
  }
  &_header {
    right: 40px;
    top: 81%;
    @media screen and (min-width: 1024px) and (max-width: 1279px) {
      right: 33px;
    }
    @media screen and (max-width: 1023px) {
      position: static;
      display: inline-block;
      margin-left: -20px;
      transform: none;
    }
  }
  &_footer {
    bottom: 100%;
    right: 0;
    transform: translateY(50%);
    @media screen and (max-width: 1023px) {
      width: 140px;
      right: 10px;
      top: 20px;
      bottom: auto;
      background: none;
      color: $color-accent;
      fill: $color-accent;
      line-height: normal;
    }
  }
}