.title {
  font-size: 2.6em;
  font-weight: 300;
  letter-spacing: .075em;
  margin: 0 0 37px;
  text-align: center;
  text-transform: uppercase;
  @media screen and (min-width: 1024px) and (max-width: 1279px) {
    margin-bottom: 45px;
  }
  @media screen and (max-width: 1023px) {
    font-size: 1.5em;
    margin-bottom: 25px;
  }
  small {
    display: block;
    font-size: .45em;
    line-height: 1;
  }
}