$sprites: (
	"88": (
		normal: (
			x: 0,
			y: 0,
			width: 39,
			height: 39,
			image: "sprites.png",
			total_width: 39,
			total_height: 39,
		),
		retina: (
			x: 0,
			y: 0,
			width: 78,
			height: 78,
			image: "sprites@2x.png",
			total_width: 78,
			total_height: 78,
		),
	),
);

@each $name in map-keys($sprites) {
	.sprite-#{$name} {
		@include sprite($name);
	}
}
