.welcome {
    background-image: url(../img/welcome-bg.jpg);
    // background-color: #4c4a4e;
    background-position: center bottom;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    z-index: 8;
    padding: 250px 0 80px;
    @media screen and (min-width: 1024px) and (max-width: 1279px) {
        padding: 208px 0 64px;
    }
    @media screen and (max-width: 1023px) {
        padding: 260px 0 40px;
        background-position: 40% 50%;
    }
    @media screen and (max-width: 575px) {
        background-position: 50% 64px;
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url(../img/back1-mob.jpg);
        padding: 90px 0 40px;
    }
    &__container {
        padding: 0 15px;
        display: flex;
        justify-content: space-between;
        @media screen and (min-width: 1024px) and (max-width: 1279px) {
            padding-right: 10px;
        }
        @media screen and (max-width: 1023px) {
            flex-direction: column;
            padding: 0;
        }
    }
    &__header {
        width: 560px;
        @media screen and (min-width: 1024px) and (max-width: 1279px) {
            width: 500px;
        }
        @media screen and (max-width: 1023px) {
            width: auto;
        }
    }
    &__title {
        color: $color-accent;
        font-size: 4.5em;
        font-weight: 300;
        line-height: 1.25;
        margin: 0 0 55px;
        text-transform: uppercase;
        @media screen and (max-width: 1023px) {
            font-size: 2em;
            margin: 0 0 10px;
        }
    }
    &__description {
        font-size: 1.7em;
        @media screen and (max-width: 1023px) {
            font-size: 1em;
        }
    }
    &__series {
        background: rgba($color-light-violet, 0.85);
        margin-top: 154px;
        padding: 43px 39px 221px;
        width: 460px;
        max-height: 336px;
        @media screen and (min-width: 1024px) and (max-width: 1279px) {
            margin-top: 125px;
            width: 391px;
            padding: 36px 33px 178px;
        }
        @media screen and (max-width: 1023px) {
            width: 100%;
            margin-top: 20px;
            padding: 16px 16px 80px;
        }
        h3 {
            color: $color-pink;
            font-size: 0.8em;
            letter-spacing: .5em;
            margin: 0 0 7px;
            text-transform: uppercase;
        }
    }
    &__links {
        display: flex;
        justify-content: flex-start;
    }
    &__item {
        border-bottom: 4px solid $color-pink;
        color: white;
        display: block;
        font-size: 1.7em;
        font-weight: 300;
        letter-spacing: 0.16em;
        line-height: 1;
        padding: 10px 0;
        text-decoration: none;
        text-transform: uppercase;
        transition: border-bottom-color 0.2s;
        &:not(:last-child) {
            margin-right: 44px;
            @media screen and (max-width: 1023px) {
                margin-right: 30px;
            }
        }
        @media screen and (max-width: 1023px) {
            border-bottom-width: 3px;
            font-size: 1.5em;
            padding: 5px 0;
        }
        &:hover {
            border-color: transparent;
        }
    }
    &__next {
        background: url("../img/arrow-down-first-screen.svg") 50% 50% no-repeat;
        background-size: contain;
        border-radius: 100%;
        width: 45px;
        height: 45px;
        left: 50%;
        position: absolute;
        bottom: -22px;
        transform: translateX(-50%);
        @media screen and (max-width: 1279px) {
            height: 34px;
            width: 34px;
            bottom: -17px;
        }
    }
}