.button-slide-down {
  background: $color-accent;
  color: $color-white;
  fill: $color-white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 72px;
  padding: 0 35px 0 40px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 1.28em;
  letter-spacing: .2em;
  line-height: 72px;
  text-decoration: none;
  text-transform: uppercase;
  @media screen and (min-width: 1024px) and (max-width: 1279px) {
    height: 60px;
    line-height: 60px;
    padding: 0 20px;
  }
  @media screen and (max-width: 1023px) {
    font-size: 1em;
    height: 60px;
    line-height: 60px;
    padding: 0 34px 0 20px;
  }
  svg {
    height: 1.5em;
    width: 1em;
    @media screen and (max-width: 1023px) {
      height: 1.8em;
      width: 1.2em;
    }
  }
}