.footer {
    background: #E8EBF0;
    &__container {
        display: flex;
        justify-content: space-between;
        padding: 105px 0 115px;
        position: relative;
        @media screen and (max-width: 1023px) {
            flex-wrap: wrap;
            padding: 32px 0;
        }
    }
    &__logo {
        background: $color-accent;
        display: block;
        height: 90px;
        line-height: 90px;
        text-align: center;
        width: 198px;
        @media screen and (min-width: 1024px) and (max-width: 1279px) {
            height: 76px;
            line-height: 76px;
            width: 164px;
        }
        @media screen and (max-width: 1023px) {
            height: 58px;
            line-height: 58px;
            margin: 0 0 24px -20px;
            padding: 0 20px;
            width: auto;
        }
        img {
            @media screen and (min-width: 1024px) {
                width: 74%;
            }
            @media screen and (max-width: 1023px) {
                height: 48%;
            }
        }
    }
    &__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 924px;
        @media screen and (min-width: 1024px) and (max-width: 1279px) {
            width: 766px;
        }
        @media screen and (max-width: 1023px) {
            flex-direction: row;
            width: 100%;
        }
    }
    &__menu {
        @media screen and (min-width: 1024px) {
            display: flex;
            justify-content: space-between;
            width: 100%;
        }
        a {
            color: inherit;
            display: block;
            font-size: 1.28em;
            line-height: 2em;
            text-transform: uppercase;
            text-decoration: none;
            transition: color .18s;
            &:hover {
                color: $color-light-violet;
            }
            @media screen and (max-width: 1023px) {
                font-size: 1.02em;
            }
        }
    }
    &__bottom {
        display: flex;
        justify-content: space-between;
        @media screen and (max-width: 1023px) {
            flex-direction: column;
            width: 140px;
        }
    }
    &__creator {
        color: #1bbcef;
        a {
            color: #1bbcef;
            font-weight: bold;
            text-decoration: none;
            text-transform: uppercase;
            @media screen and (max-width: 1023px) {
                display: block;
            }
        }
        img {
            margin-left: .2em;
            position: relative;
            top: -.075em;
        }
    }
}