* {
    box-sizing: border-box;
}

html {
    color: #2e2e2e;
    font: 14px/1.7 'Proxima Nova', sans-serif;
    min-width: 320px;
    overflow: hidden;
    @media screen and (max-width: 1279px) {
        font-size: 12px;
    }
}

body {
    overflow-x: hidden;
}

img {
    max-width: 100%;
    vertical-align: middle;
}

strong,
b {
    font-weight: bold;
}

.container {
    margin: 0 auto;
    width: 1180px;
    @media screen and (min-width: 1024px) and (max-width: 1279px) {
        width: 980px;
    }
    @media screen and (max-width: 1023px) {
        margin: 0 20px;
        width: auto;
    }
}

.slider-controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.4em 0;
}

.slider-controls__nav {
    display: block;
    fill: $color-pink;
}

.slider-controls__counter {
    color: $color-pink;
}

#noScriptWarning,
#outdatedBrowserWarning {
    color: white;
    padding: 12px;
    position: relative;
    text-align: center;
    z-index: 9999;
}

#noScriptWarning a,
#outdatedBrowserWarning a {
    color: white;
}

#noScriptWarning {
    background: red;
}

#outdatedBrowserWarning {
    background: orangered;
}

.slick-slide img {
    width: 100%;
}