.options {
  padding: 25px 0 43px;
  background-color: #f4f4f4;
  @media screen and (min-width: 1024px) and (max-width: 1279px) {
    height: 660px;
    overflow-y: hidden;
  }
  @media screen and (max-width: 1023px) {
    padding: 0 0 43px;
  }
  &__title {
    margin: 0;
    color: $color-white;
    font-size: 7.857em;
    font-weight: 200;
    line-height: 1.4;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    text-align: right;
    @media screen and (max-width: 1023px) {
      display: none;
    }
    svg {
      margin: 0 0 0 73px;
      @media screen and (min-width: 1024px) and (max-width: 1279px) {
        margin-left: 37px;
      }
    }
  }
  &__inner {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 1023px) {
      display: block;
    }
  }
  &__header {
    background: rgba($color-light-violet, 0.8);
    color: $color-white;
    height: 370px;
    padding: 28px 60px 72px 41px;
    position: relative;
    @media screen and (max-width: 1023px) {
      height: auto;
      padding: 30px 0;
    }
    h3 {
      font-size: 2.143em;
      font-weight: 300;
      line-height: 1.2;
      letter-spacing: 0.06em;
      text-transform: uppercase;
      margin: 0;
      @media screen and (max-width: 1023px) {
        display: none;
      }
    }
  }
  &__list {
    padding: 63px 34px 53px;
    font-size: 1.143em;
    position: relative;
    @media screen and (max-width: 1023px) {
      font-size: 1em;
      padding: 29px 0 35px;
    }
    &::after {
      content: '';
      display: block;
      width: 90px;
      height: 5px;
      position: absolute;
      bottom: 0;
      left: 34px;
      background-color: $color-light-violet;
      @media screen and (max-width: 1023px) {
        left: 0;
      }
    }
    h4 {
      color: $color-accent;
      text-transform: uppercase;
      letter-spacing: 0.2em;
      line-height: 1.5;
      margin: 0 0 27px;
    }
    ul {
      margin: 0;
      padding-left: 22px;
      line-height: 1.9;
      @media screen and (max-width: 1023px) {
        padding-left: 13px;
      }
    }
  }
  &__button {
    @media screen and (max-width: 1023px) {
      margin: 0 -20px;
    }
  }
}